import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'register',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'itsSuccess',
    loadChildren: () => import('./its-sucess/its-sucess.module').then( m => m.ItsSucessPageModule)
  },
  {
    path: 'success',
    loadChildren: ()=> import('./book-success/book-success.module').then(m=>m.BookSuccessPageModule)
  },
  {
    path: 'failed',
    loadChildren: ()=> import('./book-success/book-success.module').then(m=>m.BookSuccessPageModule)
  },
  {
    path: 'admin',
    loadChildren: ()=>import('./admin/admin.module').then(m=>m.AdminPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'fmb',
    loadChildren: () => import('./ramadan-selection/ramadan-selection.module').then( m => m.RamadanSelectionPageModule)
  },  {
    path: 'fmb-admin',
    loadChildren: () => import('./fmb-admin/fmb-admin.module').then( m => m.FmbAdminPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
